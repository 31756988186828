// TODO: This is a temp file, and soon will be replaced with new brand system.
// todo get all setting from one object
export const hostServer = window.PUBLIC_RUNTIME_VARS.PUBLIC_API_URL;
export const vclusterServer = `${hostServer}/vcluster`;
export const storageServer = `${hostServer}/s3`;
export const wsServer = hostServer.replace(/^http/, "ws");
export const gymServer = `${hostServer}/gym`;
export const podolicaServer = `${hostServer}/podolica`;
export const getBrand = ()=>{
    return process.env.NEXT_PUBLIC_BRAND_NAME;
// return "hamravesh";
};
export const getBrandNS = ()=>{
    return `brand-${process.env.NEXT_PUBLIC_BRAND_NAME}`;
// return "brand-hamravesh";
};
export const getPaasUrl = ()=>{
    return process.env.NEXT_PUBLIC_PAAS_URL ?? "";
};
export const getGymApiUrl = ()=>gymServer;
export const getApiURL = ()=>hostServer;
export const getPodolicaApiURL = ()=>podolicaServer;
export const getVclusterApiURL = ()=>vclusterServer;
export const getStorageApiURL = ()=>storageServer;
export const getWSURL = ()=>wsServer;
export const getPanelURL = ()=>`//${window.location.host}`;
