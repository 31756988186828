function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { getBrandNS, getPaasUrl } from "#i18n/BrandMethods";
import tolgee from "#i18n/config";
import axios from "axios";
import merge from "deepmerge";
import { getToken, getTokenType } from "#helpers/getTokenCredentials";
import version from "#/version.json";
import { handleUIReqError } from "./UIErrorHandler";
import { isPlainObject } from "is-plain-object";
// import useAuthStore from "#root/stores/auth";
class reqProxyConfig {
    constructor(){
        _define_property(this, "selectedOrganization", null);
    }
}
export const reqProxyGlobalConfig = new reqProxyConfig();
const defproxyConfig = {
    sendIssue: true,
    showNotification: true,
    needAuth: true
};
export const reqProxyOrg = {
    name: ""
};
export const paasUrl = getPaasUrl();
export const sentryUrl = tolgee.t({
    ns: getBrandNS(),
    key: "service_names.sentry_url"
});
const reqCommand = {
    cancelAll: false
};
const setAxiosConfig = ()=>{
    var _getToken, _reqProxyGlobalConfig_selectedOrganization;
    const tokenType = getTokenType();
    const config = {
        headers: {
            Authorization: `${tokenType} ${(_getToken = getToken(true, null, false)) === null || _getToken === void 0 ? void 0 : _getToken.token}`,
            "x-organization": reqProxyGlobalConfig === null || reqProxyGlobalConfig === void 0 ? void 0 : (_reqProxyGlobalConfig_selectedOrganization = reqProxyGlobalConfig.selectedOrganization) === null || _reqProxyGlobalConfig_selectedOrganization === void 0 ? void 0 : _reqProxyGlobalConfig_selectedOrganization.name,
            "x-client-version": `web/${version["x-client-version"]}`
        }
    };
    return config;
};
axios.interceptors.request.use(function(config) {
    config.headers["accept-language"] = tolgee.getLanguage();
    return config;
});
//TODO: TEST RAPIDLY NOTIF IN AUTH
export const sendRequest = async (config)=>{
    const { reqFunc, proxyConfig: proxyConfigProp, libConfig: libConfigProp, payload } = config;
    let result;
    let proxyConfig = defproxyConfig;
    if (reqCommand.cancelAll) {
        return {
            data: null
        };
    }
    const reqConfig = reqFunc(payload);
    //Overriding defaults with prop configs
    proxyConfig = merge.all([
        defproxyConfig ?? {},
        (reqConfig === null || reqConfig === void 0 ? void 0 : reqConfig.proxyConfig) ?? {},
        proxyConfigProp ?? {}
    ]);
    //TODO: Place Fetch request config like setAxiosConfig and check axios or fetch req type
    let libConfig = (proxyConfig === null || proxyConfig === void 0 ? void 0 : proxyConfig.needAuth) ? setAxiosConfig() : null;
    libConfig = merge.all([
        libConfig ?? {},
        (reqConfig === null || reqConfig === void 0 ? void 0 : reqConfig.libConfig) ?? {},
        libConfigProp ?? {}
    ], {
        isMergeableObject: isPlainObject
    });
    //####//
    if (reqConfig.makerType === "axios") {
        result = axios(libConfig).catch((error)=>{
            handleUIReqError({
                error,
                proxyConfig
            });
        });
    }
    return result;
};
export default reqProxyOrg;
