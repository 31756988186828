import { createStoreWithMiddlewares } from "../creator";
import { changePassword, requestToken, setSelectedOrganization, startForgetPass, startGettingProfile, startLogin, startLogout, startRegister, startResendEmail, startResetPass, startUpdateOrgMembers, startUpdateProfile, startVerifyEmail, startVerifyPhoneNumber } from "./user";
import { startGetPermissions, startGetRoles, startGetUserPermissions, startSetACL, startUpdateAppPermissions } from "./permissions";
const useAuthStore = createStoreWithMiddlewares("auth", (set, get)=>({
        first_name: null,
        last_name: null,
        full_name: null,
        organizations: null,
        organization_roles: null,
        announcements: null,
        id: null,
        email: null,
        is_email_verified: null,
        is_verified: null,
        mobile: null,
        national_id: null,
        is_national_id_verified: null,
        is_mobile_verified: null,
        creation_time: null,
        profile_settings: null,
        selectedOrganization: null,
        roles: null,
        uid: null,
        userPermissions: {
            apps: null,
            create_permissions: {
                create_app: null
            }
        },
        startLogin: async (user)=>{
            await startLogin(user, set);
        },
        startGetPermissions: async ()=>{
            await startGetPermissions(set);
        },
        startGetRoles: async ()=>{
            await startGetRoles(set);
        },
        startGetUserPermissions: async (userId)=>{
            await startGetUserPermissions(userId, set);
        },
        startUpdateAppPermissions: async (activeId, json, defultPermissions)=>{
            await startUpdateAppPermissions(activeId, json, defultPermissions);
        },
        startSetACL: async (is_acl_enabled, orgId)=>{
            await startSetACL(is_acl_enabled, orgId, set, get);
        },
        startUpdateOrgMembers: async (members, orgId, successMessage, storeSetter)=>{
            await startUpdateOrgMembers(members, orgId, successMessage, storeSetter);
        },
        requestToken: async (mobile)=>{
            return requestToken(mobile);
        },
        startVerifyPhoneNumber: async (mobile, token)=>{
            return startVerifyPhoneNumber(mobile, token, set);
        },
        changePassword: async (current_password, new_password)=>{
            await changePassword(current_password, new_password, set, get);
        },
        startUpdateProfile: async function(json) {
            let sendMessage = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, optimistic = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
            return startUpdateProfile(json, sendMessage, optimistic, set);
        },
        startGettingProfile: async ()=>{
            await startGettingProfile(set);
        },
        setSelectedOrganization: (selectedOrganization)=>{
            setSelectedOrganization(selectedOrganization, set);
        },
        startVerifyEmail: async (email, token)=>{
            return startVerifyEmail(email, token);
        },
        startResendEmail: async ()=>{
            await startResendEmail();
        },
        startLogout: async function() {
            let redirect = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, sendRequest = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
            await startLogout(redirect, sendRequest, set);
        },
        startForgetPass: async (json)=>{
            await startForgetPass(json);
        },
        startResetPass: async (token, new_password)=>{
            await startResetPass(token, new_password);
        },
        startRegister: async (props)=>{
            await startRegister(props, get);
        }
    }));
export default useAuthStore;
