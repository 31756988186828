import { getBrandNS } from "#i18n/BrandMethods";
import tolgee from "#i18n/config";
/* eslint-disable regex/invalid */ import Num2persian from "num2persian";
import { PERSIAN_LETTERS_REGEX } from "./validator";
export const numberWithCommas = function(number) {
    let decimals = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 2;
    if (number || number == 0) {
        var _parts_;
        if (!isNaN(number) && tolgee.t({
            ns: getBrandNS(),
            key: "currency_type"
        }) !== "en-US") {
            number = Math.floor(number);
        }
        const parts = number.toString().split(".");
        if (decimals && (parts === null || parts === void 0 ? void 0 : (_parts_ = parts[1]) === null || _parts_ === void 0 ? void 0 : _parts_.length) > decimals) {
            parts[1] = parts[1].slice(0, decimals);
        }
        const num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] || parts[1] === "" ? "." + parts[1] : "");
        return num;
    }
    return "";
};
export const numberToWords = (number)=>{
    if (number !== null) {
        return tolgee.getLanguage() == "fa" ? Num2persian(parseInt(number)) : new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "long"
        }).format(parseInt(number));
    }
    return "";
};
export const persianNumber = (number)=>{
    const farsiDigits = [
        "۰",
        "۱",
        "۲",
        "۳",
        "۴",
        "۵",
        "۶",
        "۷",
        "۸",
        "۹"
    ];
    if (number || number == 0) {
        return tolgee.getLanguage() == "fa" ? number.toString().replace(/\d/g, (n)=>farsiDigits[n]) : number;
    }
    return "";
};
export const englishNumber = (number)=>{
    const persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g
    ], arabicNumbers = [
        /٠/g,
        /١/g,
        /٢/g,
        /٣/g,
        /٤/g,
        /٥/g,
        /٦/g,
        /٧/g,
        /٨/g,
        /٩/g
    ];
    if (number !== null || number !== undefined) {
        if (typeof number === "string") {
            for(var i = 0; i < 10; i++){
                number = number.toString().replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
            }
        }
        return number;
    }
    return null;
};
export function capitalizeString(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
}
export const directionClass = (str)=>{
    return PERSIAN_LETTERS_REGEX.test(str === null || str === void 0 ? void 0 : str.trim()) ? "rtl" : "ltr";
};
export const getTextAlignment = (str)=>{
    return PERSIAN_LETTERS_REGEX.test(str === null || str === void 0 ? void 0 : str.trim()) ? "text-right" : "text-left";
};
